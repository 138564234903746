const ThirdEntryLark = () => {
    return (<div>
    <p>
        <li>
            <ul>Cole, where's Robin?!</ul>
            <ul>I-I dunno. After I ran, I lost track of her!</ul>
            <ul>What do you mean you lost track of her?! She was with us!!</ul>
            <ul>I-I don't know!</ul>
            <ul>And do you know where she is, Clemmie?!</ul>
            <ul>No... I was too scared... too terrified...</ul>
            <ul>Kingsley, please calm down... it all happened so fast, the strings, the ropes, the screams... we're safe now okay?</ul>
            <ul>I... I'm sorry...</ul>
            <ul>We were meant to be friends forever, together forever, was that all just some bullshit? YOU told them about the goddamn ritual!!</ul>
            <ul>AND WAS I S--... nng.. I couldn't know they were going to... I just... wanted to help...</ul>
            <ul>It's okay Cole, I... believe you, but... this is still unforgivable, what they did...</ul>
            <ul>L-look I'll fix it, okay? I'll take all of it from them, they won't remember it was us, okay? T-that way, they won't... use us again. And we can... pretend it...</ul>
            <ul>Pretend it never happened, huh. Oh yeah, that's easy.</ul>
            <ul>K-Kingsley please understand...</ul>
            <ul>I need some time alone.</ul>
        </li>
    </p>
    <p>3 entries remaining.</p>
    </div>
    );
  };
  
  export default ThirdEntryLark;