const FourthEntryLark = () => {
    return (<div>
    <p>
        <li>
            <ul>Oh, Kingsley... I know. But... it's over now. It really is.</ul>
            <ul>They're not going to remember either, huh...</ul>
            <ul>Honestly, it's better that way. I promise.</ul>
            <ul>But... if they don't know, what do we... do to make them remember?</ul>
            <ul>We won't make them remember. We can't.</ul>
            <ul>I know it's... the right choice, Cole, but... </ul>
            <ul>Look. We'll keep doing what we do. We always have, right? We'll keep playing.</ul>
            <ul>I don't want to play! Not for them! Not for these people!</ul>
            <ul>It's okay... it's okay, Kingsley...</ul>
            <ul>*crying* HOW CAN IT BE OKAY?! We lost Robin because of them!!</ul>
            <ul>A-am I.. interrupting? Oh Kingsley, what's wrong?</ul>
            <ul>C-Clemmie... and Perrine... it's nothing. It's nothing! Dammit!</ul>
            <ul>I-I'm sorry, maybe we can c-come back later...?</ul>
            <ul>Hey, guys? Can I... play a song for you? Just to... ...soothe things out a bit.</ul>
            <ul>*all 3 together* O-okay..?</ul>
            <ul>S-sure Cole... I think I need to wind down...</ul>
            <ul>I promise. This'll wipe away at least some of your sorrow, Kingsley...</ul>
            <ul>And to those reading... the next word is my real name. </ul>
        </li>
    </p>
    <p>2 entries remaining.</p>
    </div>
    );
  };
  
  export default FourthEntryLark;