const SixthEntryLark = () => {
    return (<div>

<p>Quill took us. They strung us up, hung us at the power lines, and subjected us to horrors I've never seen... we've never seen before.</p>
<p>We all fled the scene, and I knew our dreams were going to be wracked for a long time... </p>
<p>I didn't think Quill would act in such an... opportunistic manner. We trusted them. Fully. So I figured... Quill wasn't in control.</p>
<p>I took Quill's memories. Sealed them in a song. Harpy Hare is a song with that kind of meaning. I didn't want Quill to suffer.</p>
<p>We lost Robin that day. She became "The Boy Without a Name". Her heart was destroyed by Quill's ritual. So much so... everything started unravelling.</p>
<p>I then... took my friends' memories. Sealed them in a song... I don't... want to play that song. They don't need to remember.</p>
<p>They should look toward a future I created for them. I'm sorry I couldn't tell you.</p>
<p>As you read this, it's likely I'm taking my own life. Because I know you reached that point, and I can't... suffer breaking down like what happened to the others.</p>
<p>I'm sorry...</p>
    </div>
    );
  };
  
  export default SixthEntryLark;