const FirstEntry = () => {
    return (<div>
    <h1>On Fate, Forces and Time - Addendum 1</h1>
    <h3>Notes on dissertation by Maximillian Redford, Adept of Time, Master of Forces, student of the Watchtower of the Lunargent Thorn</h3>
    <h3>Special collaboration from Annabelle Thériault, Lady Frostglass</h3>

    <p>
        Note that the sigil can observe nudges in storylines, and can, with enough preparation, stabilize these storylines. However, use of their stronger
        elements, is strictly prohibited by all Mage orders, due to the potentially devastating effects of partial fated stabilization of an area. This is
        both the opinion of many Acanthus mages, as well as our collaborators from the fae. These "story hooks" seem to have breaking conditions, and it is
        likely that leaving them where they reside is best. For now. It is likely that they are put in place by individuals, and not a simple whim of Fate. 
        Lady Frostglass seems to have a general idea of who may have placed at least one. The questioning and interview with that individual can be found in
        a future addendum.
    </p>
    <p>5 addendums remaining.</p>
    </div>
    );
  };
  
  export default FirstEntry;