const GOTVSecondEntry = () => {
    return (<div>
    <h1>On Fate, Forces and Time - Addendum 2</h1>
    <h3>Notes on dissertation by Maximillian Redford, Adept of Time, Master of Forces, student of the Watchtower of the Lunargent Thorn</h3>
    <h3>Special collaboration from Annabelle Thériault, Lady Frostglass</h3>

    <p>
        On Bedlam -

        It would seem that particularly powerful instances of Bedlam can create fractures in the continuum, and fragilize both storylines, and time itself.
        This is unusual. In the past, these Bedlam instances only affected the psyches of mortal creatures, and nothing beyond. This is a concerning matter.
        These fractures need to be observed and studied, if they happen again. They also seem to be correlated to the "loop" phenomenon observed. A term will
        be given to these phenomena in a next addendum.
    </p>
    <p>4 addendums remaining.</p>
    </div>
    );
  };
  
  export default GOTVSecondEntry;

  