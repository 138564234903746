const FifthEntryLark = () => {
    return (<div>
    <p>
        <li>
            <ul>Hmm... So Clemmie really thinks it's gonna happen again?</ul>
            <ul>Nah, there's no way! And they trust us already, and we're there to help!</ul>
            <ul>Y-yeah but Kingsley I dunno if I even want to have such a big part this time around.</ul>
            <ul>Cole, get a hold of yourself, sheesh. This is our act, it's what we do!</ul>
            <ul>You're not going to.. leave too, right?</ul>
            <ul>What no of course I won't you idiot. Someone needs to watch you 'round Clemmie anyway.</ul>
            <ul>W-what do you mean by that?!</ul>
            <ul>You know what I mean...</ul>
            <ul>I-it's not like that!</ul>
            <ul>It's totally like that.</ul>
            <ul>...okay yes it IS like that but shut up. Sh-shut up!</ul>
            <ul>Look, Milio was a lot of fun, but he didn't leave cuz of you, moron. He had other aspirations!</ul>
            <ul>I-I just don't want you to---- ...huh? What's...</ul>
            <ul>I. Won't. Leave. Who else is gonna feed you crickets while you sleep anyway?</ul>
            <ul>W-what?</ul>
            <ul>Need to get more from that vendor, now that I think about it...</ul>
            <ul>W-what vendor??</ul>
            <ul>You know, the one that sold you the mark?</ul>
            <ul>Oh yeah... W-wait you're feeding me crickets?!</ul>
            <ul>What.. are you two talking about?</ul>
            <ul>N-nothing! Go play with Clemmie and Perrine, haha!</ul>  
            <ul>Hey Cole? I like having the four of us around. We make an awesome group! ...</ul>
            <ul>...don't worry. I like having the four of us around too. Don't strain your mind over it.</ul>
            <ul>I'll shoulder it. I gave the key to Milio anyway. 73rd.</ul>  
        </li>
    </p>
    <p>1 entry remaining.</p>
    </div>
    );
  };
  
  export default FifthEntryLark;