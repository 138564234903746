const SixthEntry = () => {
    return (<div>
    <h1>Quill's Journal</h1>
    <h3>Entry - October 26th 2019</h3>
    <p>
        I think it's over. The fifth one was offered at the power lines. With this, the Thousand-Day Promise should hold...<br/>
        I fear, however, that this one may bring unforeseen problems. They were children, certainly, but this last one...<br/>
        The Beast's arrival last year has put the court on edge... perhaps it's time I make my move. His name shouldn't be pronounced...
    </p>
    <br/>
    <h3>Entry - November 13th 2019</h3>
    <p>
        My knowledge of the customs and of the Bulwark itself seems to have attracted the favor of Autumn. Benjamin doesn't seem to have <br/>
        noticed my actions towards the Promise, and it's best it remained that way... he does refuse to speak to me most of the time however...<br/>
        I truly hope my writing is still good enough. I disappointed Him once before.
    </p>
    <br/>
    <h3>Entry - November 28th 2019</h3>
    <p>
        The Promise has held! We shouldn't see a whole lot of turmoil for the next 3 years or so. The sacrifice is... sadly worth it.<br/>
        And if they find out, they'll understand. There's nothing stronger to keep other children safe... just thinking about it<br/>
        does make me feel sick... that mask will haunt me for months. He was only 13...
    </p>
    <h3>Entry - December 14th 2019</h3>
    <p>
        My memories are failing me. I can't remember the faces of the children affected. I know the effects are still active, as my<br/>
        nightmares are still very present, but I can't remember. My dreams have this new thing now, a female voice humming<br/>
        "La-la-la, la-la-la, la-la-la" in a sing-songy way... I don't recognize the voice, but... I feel like I should...
    </p>
    <h3>Entry - January 1st 2020</h3>
    <p>
        Milio's appearance completely changed from one day to the next. He doesn't recall anything changing, and says he's always looked<br/>
        the same. Even pictures of him on my phone changed to his new appearance. What's going on? I need to go talk to... ugh... him again.<br/>
        He'll know about this, he's a pro at mirrors, and it looks like a use of Chrysalis.
    </p>
    <h3>Entry - February 12th 2020</h3>
    <p>
        6 weeks of research with the grossest guy in the world. At least we got results, Milio's appearance has been affected by contracts<br/>
        of Reflection. By whom, or why, no one knows, but it's likely his appearance got overwritten by someone's reflection, or something close<br/>
        to it. 
    </p>
    <p>5 entries remaining.</p>

    </div>
    
    );
  };
  
  export default SixthEntry;